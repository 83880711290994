import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8f6db06"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "the-search-result-main",
  role: "main"
}
const _hoisted_2 = {
  class: "the-search-result-main-header",
  "aria-live": "polite"
}
const _hoisted_3 = {
  key: 0,
  class: "the-search-result-main-total"
}
const _hoisted_4 = {
  key: 0,
  class: "has-text-title has-text-contents-title",
  id: "search-result-total-hits-message"
}
const _hoisted_5 = { "aria-hidden": "true" }
const _hoisted_6 = { class: "is-sr-only" }
const _hoisted_7 = {
  key: 1,
  class: "has-text-title has-text-contents-title",
  id: "search-result-total-hits-message"
}
const _hoisted_8 = { id: "itemTotalHits" }
const _hoisted_9 = {
  key: 0,
  id: "error-message"
}
const _hoisted_10 = {
  key: 1,
  class: "search-not-found has-background-contents shadow level-center is-mobile"
}
const _hoisted_11 = { class: "the-search-result-main-show-facet level-right is-mobile is-hidden-desktop has-background-contents" }
const _hoisted_12 = { class: "the-search-result-main-nav is-hidden-mobile level-right has-background-contents" }
const _hoisted_13 = { class: "the-search-result-main-item-list" }
const _hoisted_14 = { class: "the-search-result-main-nav-mobile" }
const _hoisted_15 = { class: "has-background-contents shadow" }
const _hoisted_16 = { class: "has-text-table-cell-title" }
const _hoisted_17 = { class: "has-background-contents shadow" }
const _hoisted_18 = { class: "has-text-table-cell-title" }
const _hoisted_19 = { class: "the-search-result-main-pager" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_ItemListView = _resolveComponent("ItemListView")!
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_RadioButtonList = _resolveComponent("RadioButtonList")!
  const _component_PageNavigation = _resolveComponent("PageNavigation")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hasError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.total > 0)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('label.searchResult') + ' '), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('label.result' , { start: _ctx.toLocale(_ctx.start + 1, _ctx.lang), end: _ctx.toLocale(_ctx.end, _ctx.lang), total: _ctx.toLocale(_ctx.total, _ctx.lang) })), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('label.resultScreenReader' , { start: _ctx.toLocale(_ctx.start + 1, _ctx.lang), end: _ctx.toLocale(_ctx.end, _ctx.lang), total: _ctx.toLocale(_ctx.total, _ctx.lang) })), 1)
                ]))
              : (_openBlock(), _createElementBlock("h1", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('label.searchResult') + ' '), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.toLocale(_ctx.total, _ctx.lang) + ' '), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('label.case')), 1)
                ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('message.invalidSearchQuery')), 1))
      : (_ctx.total==0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.notFound')), 1)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.itemList.length)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["the-search-result-main-posts", {
          'list-view': _ctx.displayMode === 'list',
          'thumbnail-view': _ctx.displayMode === 'thumbnail'
        }])
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_AppButton, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal())),
                      class: "button-normal",
                      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.showModal()), ["prevent"]), ["space","enter"])),
                      styled: "is-normal",
                      id: "sorting-button"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('form.sorting')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.displayMode,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayMode) = $event)),
                      options: _ctx.displayModeLocale,
                      componentId: "the-selectbox-display-mode"
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.displayedItemCount,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displayedItemCount) = $event)),
                      options: _ctx.displayLocale,
                      componentId: "the-selectbox-page-size"
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.sort,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort) = $event)),
                      options: _ctx.sortLocale,
                      componentId: "the-selectbox-sort-key"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item, index) => {
                      return (_openBlock(), _createBlock(_component_ItemListView, {
                        index: index,
                        item: item,
                        key: `item-list-view-${item.id}`,
                        tag: "article",
                        class: "the-search-result-main-item-article",
                        selectValue: item.id,
                        displayMode: _ctx.displayMode
                      }, null, 8, ["index", "item", "selectValue", "displayMode"]))
                    }), 128))
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.state.showModal)
              ? (_openBlock(), _createBlock(_Teleport, {
                  key: 1,
                  to: "#search-result-mobile-menu"
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("section", _hoisted_15, [
                      _createVNode(_component_CloserController, {
                        modelValue: _ctx.state.showSortList,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.showSortList) = $event)),
                        icons: ['accordion-open', 'arrow-right'],
                        class: "has-background-modest"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_16, _toDisplayString(_ctx.$t('form.order')), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      (_ctx.state.showSortList)
                        ? (_openBlock(), _createBlock(_component_RadioButtonList, {
                            key: 0,
                            modelValue: _ctx.sort,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
                            items: _ctx.sortLocale.map((item) => ({ label: item.name, value: item.value })),
                            class: "has-text-contents-normal has-form-text-normal",
                            id: "sort-type",
                            name: "sort-type",
                            mobileFlag: true
                          }, null, 8, ["modelValue", "items"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("section", _hoisted_17, [
                      _createVNode(_component_CloserController, {
                        modelValue: _ctx.state.showDisplayList,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.showDisplayList) = $event)),
                        icons: ['accordion-open', 'arrow-right'],
                        class: "has-background-modest"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_18, _toDisplayString(_ctx.$t('form.displayNum')), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      (_ctx.state.showDisplayList)
                        ? (_openBlock(), _createBlock(_component_RadioButtonList, {
                            key: 0,
                            modelValue: _ctx.displayedItemCount,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.displayedItemCount) = $event)),
                            items: _ctx.displayLocale.map((item) => ({ label: item.name, value: item.value })),
                            id: "display-num",
                            name: "display-num",
                            mobileFlag: true
                          }, null, 8, ["modelValue", "items"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_PageNavigation, {
                modelValue: _ctx.pageNumber,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pageNumber) = $event)),
                displayCount: 5,
                items: _ctx.pageNavigationIndex
              }, null, 8, ["modelValue", "items"])
            ])
          ], 64))
  ]))
}